var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-layout-wrapper", attrs: { id: "userLayout" } },
    [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "main" },
          [
            _c(
              "el-form",
              {
                ref: "registerForm",
                attrs: { model: _vm.registerForm, rules: _vm.rules },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submitForm($event)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入用户名" },
                        model: {
                          value: _vm.registerForm.username,
                          callback: function($$v) {
                            _vm.$set(_vm.registerForm, "username", $$v)
                          },
                          expression: "registerForm.username"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-input__icon el-icon-user",
                          attrs: { slot: "suffix" },
                          slot: "suffix"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.lock === "lock" ? "password" : "text",
                          placeholder: "请输入密码"
                        },
                        model: {
                          value: _vm.registerForm.password,
                          callback: function($$v) {
                            _vm.$set(_vm.registerForm, "password", $$v)
                          },
                          expression: "registerForm.password"
                        }
                      },
                      [
                        _c("i", {
                          class: "el-input__icon el-icon-" + _vm.lock,
                          attrs: { slot: "suffix" },
                          on: { click: _vm.changeLock },
                          slot: "suffix"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { position: "relative" },
                    attrs: { prop: "rePassword" }
                  },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.lock === "lock" ? "password" : "text",
                          placeholder: "请再次输入密码"
                        },
                        model: {
                          value: _vm.registerForm.rePassword,
                          callback: function($$v) {
                            _vm.$set(_vm.registerForm, "rePassword", $$v)
                          },
                          expression: "registerForm.rePassword"
                        }
                      },
                      [
                        _c("i", {
                          class: "el-input__icon el-icon-" + _vm.lock,
                          attrs: { slot: "suffix" },
                          on: { click: _vm.changeLock },
                          slot: "suffix"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { type: "primary" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v("注 册")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._m(1)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "desc" }, [
        _c("img", {
          staticClass: "logo_login",
          attrs: { src: require("@/assets/logo_login.png"), alt: "" }
        })
      ]),
      _c("div", { staticClass: "header" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("span", { staticClass: "title" }, [_vm._v("Gin-Vue-Admin")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "links" }, [
        _c("a", { attrs: { href: "http://doc.henrongyi.top/" } }, [
          _c("img", {
            staticClass: "link-icon",
            attrs: { src: require("@/assets/docs.png") }
          })
        ]),
        _c("a", { attrs: { href: "https://www.yuque.com/flipped-aurora/" } }, [
          _c("img", {
            staticClass: "link-icon",
            attrs: { src: require("@/assets/yuque.png") }
          })
        ]),
        _c(
          "a",
          {
            attrs: { href: "https://github.com/flipped-aurora/gin-vue-admin" }
          },
          [
            _c("img", {
              staticClass: "link-icon",
              attrs: { src: require("@/assets/github.png") }
            })
          ]
        ),
        _c("a", { attrs: { href: "https://space.bilibili.com/322210472" } }, [
          _c("img", {
            staticClass: "link-icon",
            attrs: { src: require("@/assets/video.png") }
          })
        ])
      ]),
      _c("div", { staticClass: "copyright" }, [
        _vm._v(" Copyright © 2020 💖flipped-aurora ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }